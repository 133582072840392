module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Craig D'Arcy | JAMstack & WordPress Developer and Designer","short_name":"Craig D'Arcy","start_url":"/","background_color":"#fff","theme_color":"rgb(2, 66, 153)","display":"browser","icon":"src/images/favicon.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
